<template>
  <div>
    <filter-data :get-report="getReport" />
    <report-summary />
    <b-tabs
      pills
      justified
    >
      <b-tab
        active
        lazy
      >
        <template #title>
          <feather-icon icon="FileTextIcon" />
          <span>Rapor Özeti</span>
        </template>
        <statuses />
        <products />
      </b-tab>
      <b-tab lazy>
        <template #title>
          <feather-icon icon="ListIcon" />
          <span>Rapor Detayı</span>
        </template>
        <report-detail />
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import FilterData from '@/views/Admin/Reports/Offers/components/FilterData.vue'
import ReportSummary from '@/views/Admin/Reports/Offers/components/Summary.vue'
import Statuses from '@/views/Admin/Reports/Offers/components/Statuses.vue'
import Products from '@/views/Admin/Reports/Offers/components/Products.vue'
import ReportDetail from '@/views/Admin/Reports/Offers/components/Details.vue'
import { BTab, BTabs } from 'bootstrap-vue'

export default {
  name: 'Index',
  components: {
    BTab,
    BTabs,
    FilterData,
    ReportSummary,
    Statuses,
    Products,
    ReportDetail,
  },
  computed: {
    filterParams() {
      return this.$store.getters['offerReports/filterParams']
    },
  },
  created() {
    this.getReport()
  },
  methods: {
    getReport() {
      this.getSummaryReport()
      this.getDetailReport()
    },
    getSummaryReport() {
      this.$store.dispatch('offerReports/getReport', this.filterParams)
    },
    getDetailReport() {
      this.filterParams.limit = 25
      this.filterParams.start = 0
      this.$store.dispatch('offerReports/getDetail', this.filterParams)
    },
  },
}
</script>
