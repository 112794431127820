<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Ürünler</b-card-title>
    </b-card-header>
    <b-table
      v-if="spareparts.data.length > 0"
      responsive="sm"
      :fields="fields"
      :items="spareparts.data"
      striped
    >
      <template #cell(count)="data">
        <div class="text-primary text-center">
          {{ data.item.count | toNumber }}
          <div class="font-small-2 text-muted">
            {{ data.item.unit }}
          </div>
        </div>
      </template>
      <template #cell(listed_price)="data">
        <div class="font-weight-bold">
          {{ data.item.listed_price | toCurrency }} {{ data.item.currency }}
        </div>
      </template>
      <template #cell(discounted_price)="data">
        <div class="font-weight-bold">
          {{ data.item.discounted_price | toCurrency }} {{ data.item.currency }}
        </div>
      </template>
    </b-table>
    <empty-alert v-else />
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BTable,
} from 'bootstrap-vue'
import EmptyAlert from '@/views/Admin/Reports/common/EmptyAlert.vue'

export default {
  name: 'Products',
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BTable,
    EmptyAlert,
  },
  data() {
    return {
      fields: [
        {
          key: 'title',
          label: 'BAŞLIK',
          thClass: 'align-middle',
        },
        {
          key: 'count',
          label: 'Miktar',
          thClass: 'align-middle text-center',
          sortable: true,
        },
        {
          key: 'listed_price',
          label: 'Liste Fiyatı Ortalaması',
          thClass: 'width-200 text-right',
          tdClass: 'text-right text-nowrap',
          sortable: true,
        },
        {
          key: 'discounted_price',
          label: 'İskontolu Fiyat Ortalaması',
          thClass: 'width-200 text-right',
          tdClass: 'text-right text-nowrap',
          sortable: true,
        },
      ],
    }
  },
  computed: {
    spareparts() {
      return this.$store.getters['offerReports/products']
    },
  },
}
</script>
