<template>
  <div>
    <vue-apex-charts
      v-if="series[0].data.length > 0"
      type="bar"
      :height="heightSize"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

export default {
  name: 'HorizontalBarChart',
  components: {
    VueApexCharts,
  },
  props: {
    heightSize: {
      // eslint-disable-next-line no-bitwise,vue/require-prop-type-constructor
      type: (String | Number),
      default: 350,
    },
    seriesData: {
      type: Array,
      required: true,
    },
    categoriesData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      series: [
        {
          name: 'Adet',
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        colors: $themeColors.primary,
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: '30%',
            endingShape: 'rounded',
          },
        },
        grid: {
          xaxis: {
            lines: {
              show: false,
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: [],
          labels: {
            formatter(val) {
              return Math.floor(val)
            },
          },
        },
        tooltip: {
          x: {
            show: true,
          },
          y: {
            title: {
              formatter: seriesName => seriesName,
            },
          },
        },
      },
    }
  },
  watch: {
    seriesData: {
      deep: true,
      handler(val) {
        this.series[0].data = val
      },
    },
    categoriesData: {
      deep: true,
      handler(val) {
        this.chartOptions.labels = val
        this.chartOptions = {
          ...this.chartOptions,
          ...{
            xaxis: {
              categories: val,
            },
          },
        }
      },
    },
  },
  mounted() {
    this.setData()
  },
  methods: {
    setData() {
      this.series[0].data = this.seriesData
      this.chartOptions.labels = this.categoriesData
      this.chartOptions = {
        ...this.chartOptions,
        ...{
          xaxis: {
            categories: this.categoriesData,
          },
        },
      }
    },
  },
}
</script>
